<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "RsManagement",
  created() {
    this.$store.commit('verticalMenu/UPDATE_VERTICAL_MENU_COLLAPSED', false)
  }
}
</script>

<style scoped>

</style>